import React, { useState, useEffect, useCallback } from 'react';
import { useAptorApi } from '../../Api/useAptorApi';
import 'moment/locale/sv';
import 'moment/locale/de';
import moment from 'moment';
import { INamedEntity } from '../../Api/AptorApi';
import { jwtDecode } from 'jwt-decode';
import { getAuthProvider } from '../../Auth/AuthProvider';
export type Locale = 'sv' | 'en';

type User = {
  email: string;
  id: number;
  locale: Locale;
  changeLocale: (newLocale: Locale) => void;
  firstName: string;
  lastName: string;
  name:string,
  language: Language;
  tableRowCount: number;
  mailSettings: MailSettings;
  department: string | null;
  position: string | null;
  phoneNumber: string | null;
  authorizationPending: boolean;
  authorizationFailed: boolean;
  serverError: boolean;
  featureAccess: UserFeature[];
  customerName: string | null;
  customerId: number | null;
  companyUnits: INamedEntity[];
  hasAccessToModule: (module: Module) => boolean;
  hasAccessToFeature: (feature: Feature, accessLevel: AccessLevel) => boolean;
  userImpersonation?: string;
  supportInvitation?: SupportInvitation;
  impersonateUser: (email?: string) => void;
  impersonateUserWithInvitation: (invitation?: SupportInvitation) => void;
  complianceUserFor: Compliances;
  fetchUser: (showLoadingIndicator?: boolean) => Promise<void>;
  getCompanyUnitName: (formatMessage: (params: { id: string }) => string, lowercase?: boolean) => string;
};

type ComplianceEntity = { id: number; customerCompanyUnitId: number };
type Compliances = { laws: Array<ComplianceEntity>; requirements: Array<ComplianceEntity> };

type Language = { value: number; displayName: string };
type MailSettings = { value: number; displayName: string };

export enum UserStatus {
  Invited = 'invited',
  Active = 'active',
  Inactive = 'inactive',
}

export enum AccessLevel {
  None = 0,
  Read = 1,
  Manage = 2,
  Administer = 4,
}

export enum Module {
  LawPortal = 1,
  Administration = 2,
  Organization = 3,
}

export enum AdminFeature {
  Administrate = 0,
}

export enum LawPortalFeature {
  Manage = 1,
  OurLaws = 2,
  OtherRequirements = 4,
  Statistics = 6,
  InviteAptor = 7,
}

export enum OrganizationFeature {
  Manage = 10,
  AAD = 11,
}

export enum PlanFeature {
  plan = 'Plan',
  requirement = 'Requirement',
}
export enum planType{
  legislation=0,
  requirement=1,
}

type Feature = AdminFeature | LawPortalFeature | OrganizationFeature;

type UserFeature = {
  feature: Enumeration & { module: Enumeration };
  accessLevel: Enumeration;
};

type Enumeration = {
  value: number;
  displayName: string;
  name: string;
};

export const IMPERSONATION_KEY = 'impersonatedAs';
export const IMPERSONATION_INVITATION_KEY = 'supportInvitation';
export type SupportInvitation = { id: string; validTo: Date };

export const useUser = (): User => {
  const [locale, changeLocale] = useState<Locale>('sv');
  const { api, abortController } = useAptorApi();
  const [id, setId] = useState<number>(0);
  const [userInitialized, setUserInitialized] = useState<boolean>(false);
  const [authorizationPending, setAuthorizationPending] = useState<boolean>(true);
  const [authorizationFailed, setAuthorizationFailed] = useState<boolean>(false);
  const [serverError, setServerError] = useState<boolean>(false);
  const [userImpersonation, setUserImpersonation] = useState<string | undefined>(
    localStorage.getItem(IMPERSONATION_KEY) || undefined,
  );

  const storedInvitation = localStorage.getItem(IMPERSONATION_INVITATION_KEY);
  const [supportInvitation, setSupportInvitation] = useState<SupportInvitation | undefined>(
    (storedInvitation && JSON.parse(storedInvitation)) || undefined,
  );
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [tableRowCount, setTableRowCount] = useState<any>(null);
  const [email, setEmail] = useState<string>('');
  const [department, setDepartment] = useState<string | null>(null);
  const [position, setPosition] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [featureAccess, setFeatureAccess] = useState<UserFeature[]>([]);
  const [customerName, setCustomerName] = useState<string | null>(null);
  const [customerId, setCustomerId] = useState<number | null>(null);
  const [companyUnits, setCompanyUnits] = useState<Array<{ id: number; name: string }>>([]);
  const [customerCompanyUnitAlias, setCustomerCompanyUnitAlias] = useState<string | null>(null);
  const [language, setLanguage] = useState<Language>({ value: 0, displayName: 'Svenska' });
  const [mailSettings, setMailSettings] = useState<MailSettings>({ value: 0, displayName: '' });
  const [complianceUserFor, setComplianceUserFor] = useState<Compliances>({ laws: [], requirements: [] });
  const hasAccessToModule = useCallback(
    (module: Module) => {
      return featureAccess.some((feature) => feature.feature.module.value === module);
    },
    [featureAccess],
  );

  const hasAccessToFeature = useCallback(
    (feature: Feature, accessLevel: AccessLevel) => {
      return featureAccess.some((fa) => fa.feature.value === feature && fa.accessLevel.value >= accessLevel);
    },
    [featureAccess],
  );

  useEffect(() => {
    moment.locale(locale);
  }, [locale]);

  const fetchUser = useCallback(
    async (showLoadingIndicator?: boolean) => {
      setAuthorizationFailed(false);
      setServerError(false);
      try {
        if (showLoadingIndicator) {
          setAuthorizationPending(true);
        }
        const user = await api.getUser();
        if (user && user.email) {
          setId(user.id);
          setEmail(user.email);
          setFirstName(user.firstName);
          setName(user.name)
          setLastName(user.lastName);
          setDepartment(user.department);
          setPosition(user.position);
          setLanguage(user.language);
          setMailSettings(user.mailSettings);
          setPhoneNumber(user.phoneNumber);
          changeLocale(user.language.cultureKey);
          setUserInitialized(true);
          setFeatureAccess(user.featureAccess);
          setCustomerName(user.customerName ?? null);
          setCustomerId(user.customerId ?? null);
          setCustomerCompanyUnitAlias(user.customerCompanyUnitAlias ?? null);
          setComplianceUserFor(user.complianceUserFor);
          setCompanyUnits(user.companyUnits);
          setTableRowCount(user.tableRowCount ?? null);
        }
      } catch (exception) {
        if (abortController.current.signal.aborted) {
          return;
        }

        if (exception?.response?.status === 401) {
          setAuthorizationFailed(true);
        } else {
          setServerError(true);
        }
      }

      setAuthorizationPending(false);
    },
    [api, abortController],
  );
  const accessToken: any = localStorage.getItem('accessToken');
  function isTokenExpired(token: string | null): boolean {
    if (!token) return true;

    try {
      const decoded: any = jwtDecode<any>(token);
      const currentTime = Date.now() / 1000; // Current time in seconds

      // Check if the token is expired
      return decoded.exp < currentTime;
    } catch (error) {
      console.error('Invalid token:', error);
      return true;
    }
  }
  useEffect(() => {
    if (!userInitialized && accessToken) {
      if (isTokenExpired(accessToken)) {
        const authProvider = getAuthProvider();
        localStorage.clear();
        authProvider.logout();
      } else {
        fetchUser();
      }
    }
  }, [fetchUser, userInitialized]);

  const impersonateUser = useCallback(
    (email?: string) => {
      if (email) {
        localStorage.setItem(IMPERSONATION_KEY, email);
      } else {
        localStorage.removeItem(IMPERSONATION_KEY);
      }

      fetchUser(true);

      setUserImpersonation(email);
    },
    [fetchUser],
  );

  const getCompanyUnitName = useCallback(
    (formatMessage: (params: { id: string }) => string, lowerCase?: boolean) => {
      const name = customerCompanyUnitAlias ?? formatMessage({ id: 'utils.connections.alias' });
      return lowerCase ? name.toLowerCase() : name;
    },
    [customerCompanyUnitAlias],
  );

  const impersonateUserWithInvitation = useCallback(
    (invitation?: SupportInvitation) => {
      if (invitation) {
        localStorage.setItem(IMPERSONATION_INVITATION_KEY, JSON.stringify(invitation));
      } else {
        localStorage.removeItem(IMPERSONATION_INVITATION_KEY);
      }

      fetchUser(true);

      setSupportInvitation(invitation);
    },
    [fetchUser],
  );

  return {
    email,
    id: id,
    locale,
    changeLocale,
    firstName,
    lastName,
    department,
    position,
    name,
    phoneNumber,
    language,
    mailSettings,
    authorizationFailed,
    serverError,
    authorizationPending,
    featureAccess,
    hasAccessToModule,
    hasAccessToFeature,
    userImpersonation,
    supportInvitation,
    impersonateUser,
    impersonateUserWithInvitation,
    customerName,
    customerId,
    complianceUserFor,
    fetchUser,
    getCompanyUnitName,
    companyUnits,
    tableRowCount,
  };
};

export const UserContext = React.createContext<User>({
  email: '',
  id: 0,
  locale: 'sv',
  changeLocale: () => {},
  authorizationPending: true,
  serverError: false,
  authorizationFailed: false,
  language: { displayName: '', value: 0 },
  mailSettings: { displayName: '', value: 0 },
  firstName: '',
  lastName: '',
  name:'',
  department: null,
  position: null,
  phoneNumber: null,
  featureAccess: [],
  customerName: null,
  customerId: null,
  hasAccessToFeature: (_feature: Feature, _accessLevel: AccessLevel) => false,
  hasAccessToModule: (_module: Module) => false,
  userImpersonation: '',
  impersonateUser: () => {},
  supportInvitation: undefined,
  impersonateUserWithInvitation: () => {},
  complianceUserFor: { laws: [], requirements: [] },
  fetchUser: () => Promise.resolve(),
  getCompanyUnitName: () => '',
  companyUnits: [],
  tableRowCount: 0,
});
