import React, { FC, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Grid, Icon, Typography } from '@material-ui/core';
import { useConfirmation } from '../../../../../../Context/ConfirmationContext/ConfirmationContext';
import { UserContext } from '../../../../../../Context/UserContext/UserContext';
import { IRequirementChange } from '.';
import moment from 'moment';
import { TextField } from '../../../../../../Components/Field/TextField';
interface IProps {
  reqId: number;
  change: IRequirementChange;
  acknowledge?: (comment?: string) => Promise<void>;
}

export const RequirementChange: FC<IProps> = ({ reqId, change, acknowledge }) => {
  const { complianceUserFor, id } = useContext(UserContext);
  const { deleteConfirmation } = useConfirmation();
  const { formatMessage } = useIntl();

  const [comment, setComment] = useState<string>('');

  const shouldAcknowledge =
    acknowledge &&
    complianceUserFor.requirements.some((x) => x.id === reqId) &&
    change.unacknowledgedByUsers.some((x) => x === id);

  const confirmation = {
    title: formatMessage({ id: 'law-portal.requirements.changes.acknowledge.label' }),
    description: formatMessage({ id: 'law-portal.requirements.changes.acknowledge.confirmation' }),
  };

  const ack = () => {
    return acknowledge!(comment);
  };

  const handleAcknowledge = () => {
    if (acknowledge) {
      deleteConfirmation(ack, confirmation);
    }
  };

  return (
    <Grid container direction="column">
      <Typography dangerouslySetInnerHTML={{ __html: change.shortDescription }} />
      <Typography>
        <FormattedMessage
          id="law-portal.requirements.changes.effectiveFrom"
          values={{ date: moment(change.effectiveFrom).format('ll') }}
        />
      </Typography>
      {shouldAcknowledge && (
        <>
          <Grid md={6} xs={12} item style={{ marginTop: '2em', marginBottom: '1em' }}>
            <TextField
              label={formatMessage({ id: 'component.law-change.acknowledge.comment' })}
              name="comment"
              errorTexts={[]}
              onChange={(val) => setComment(val?.value || '')}
            />
          </Grid>
          <Grid item>
            <Grid container justify="flex-end">
              <Button variant="outlined" onClick={handleAcknowledge} startIcon={<Icon>check</Icon>}>
                <FormattedMessage
                  id="law-portal.requirements.changes.acknowledge.button"
                  defaultMessage="Acknowledge"
                />
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
