import React, { useState, useMemo, memo } from 'react';
import {
  TextField,
  EmailField,
  SelectField,
  AsyncSelectField,
  DateField,
  DateTimeField,
  RichTextField,
  PostalCodeField,
  NumberField,
  CheckboxField,
  PhoneNumberField,
  RadioField,
} from '../Field';
import { Grid, Button, CircularProgress, Typography, Hidden } from '@material-ui/core';
import { ErrorResponse } from '../../Api/AptorApi';
import { useAptorApi } from '../../Api/useAptorApi';
import { useSnackbar } from 'notistack';
import { useIntl, FormattedMessage } from 'react-intl';
import { useStyles } from './Form.styles';
import { Item, ItemGrid, Groups, Group, FormFieldState, FormValues } from './Form.types';
import { IFormProps } from './Form.props';
import { SelectCard } from '../Select/SelectCard';

function getItemType(item: Item): 'grid' | 'field' | 'void' {
  if (item !== null && (item.type === 'row' || item.type === 'column')) {
    return 'grid';
  } else if (item === null) {
    return 'void';
  } else {
    return 'field';
  }
}

const gridToFormFields = (grid: ItemGrid, fields: FormFieldState[]): FormFieldState[] => {
  let newFields = [...fields];
  grid.items.forEach((item) => {
    const type = getItemType(item);
    if (type === 'field') {
      const field = item as FormFieldState;
      newFields.push(field);
    } else if (type === 'grid') {
      const itemGrid = item as ItemGrid;
      newFields = [...newFields, ...gridToFormFields(itemGrid, fields)];
    }
  });

  return newFields;
};

const groupsToFormFields = (groups: Groups) => {
  let fields = [] as FormFieldState[];
  groups.forEach((group) => {
    fields = gridToFormFields(group.grid, fields);
  });

  return fields;
};

const toFormValues = (groups?: Groups, field?: FormFieldState) => {
  const formValues = {} as FormValues;

  const fieldToFormValueEntry = (field: FormFieldState) => {
    if (field.fieldRequirements && field.fieldRequirements.some((field) => field === undefined)) {
      return undefined;
    }
    const state = field.state[0];
    if (!Array.isArray(state)) {
      if (state?.value === undefined || state?.value === null) {
        return undefined;
      }
      if (field.type === 'multi-select') {
        return [state.value];
      } else {
        return state.value;
      }
    } else {
      return state.map((x) => x.value);
    }
  };
  if (groups) {
    groupsToFormFields(groups).forEach((field) => {
      const entry = fieldToFormValueEntry(field);
      if (entry !== undefined) {
        formValues[field.name] = entry;
      }
    });
  }
  if (field) {
    const entry = fieldToFormValueEntry(field);
    if (entry !== undefined) {
      formValues[field.name] = entry;
    }
  }
  return formValues;
};

const Void = memo(() => (
  <Hidden smDown>
    <Grid item sm xs={12} />
  </Hidden>
));

export const Form = (
  props: IFormProps & React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
) => {
  const { api, abortController } = useAptorApi();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { groups, singleField, submit, submitLabel,hideButton, cancel, cancelLabel, ...rest } = props;

  const classes = useStyles();

  const validForm = useMemo(() => {
    const validateFormField = (field: FormFieldState) => {
      if (field.fieldRequirements && field.fieldRequirements.some((req) => req.state[0] === undefined)) {
        return true;
      }

      if (
        field.type === 'multi-select' &&
        field.required &&
        (field.state[0] === undefined || (Array.isArray(field.state[0]) && field.state[0].length === 0))
      ) {
        return false;
      }
      if (Array.isArray(field.state[0])) {
        return (
          field.state[0].every((x) => x.isValid || x.isValid === undefined) &&
          (field.customValidation === undefined || field.customValidation(field.state[0]))
        );
      } else {
        return (
          (field.state[0]?.isValid || !field.required) &&
          (field.customValidation === undefined || field.customValidation(field.state[0]))
        );
      }
    };

    if (groups) {
      return groupsToFormFields(groups).every(validateFormField);
    }

    if (singleField) {
      return validateFormField(singleField);
    }

    return false;
  }, [groups, singleField]);

  const [errorData, setErrorData] = useState<ErrorResponse | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleFailResponse = async (response: Response) => {
    if (response.status === 400) {
      const data = (await response.json()) as ErrorResponse;
      setErrorData(data);
    } else {
      enqueueSnackbar(intl.formatMessage({ id: 'utils.snackbar.somethingWentWrong' }), { variant: 'error' });
    }
  };

  const hasErrors = (name: string): string[] => {
    if (errorData === undefined || errorData.type !== 'validation') {
      return [];
    } else {
      return (errorData.errors.find((x) => x.name.toLowerCase() === name.toLowerCase())?.errors ?? [])
        .filter((x) => x.type === 'property')
        .map((x) => x.message);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validForm) {
      try {
        setErrorData(undefined);
        setLoading(true);
        await submit(toFormValues(groups, singleField), api, (successMessage) => {
          if (successMessage !== null) {
            enqueueSnackbar(intl.formatMessage({ id: successMessage || 'utils.snackbar.saved' }), {
              variant: 'success',
            });
          }

          if (abortController.current.signal.aborted) {
            return;
          }
          setLoading(false);
        });
      } catch (exception) {
        if (abortController.current.signal.aborted) {
          return;
        }
        if (exception.response) {
          setLoading(false);
          handleFailResponse(exception.response);
        } else {
          //Not sure what kind of error this is, rethrow
          throw exception;
        }
      }
    }
  };

  const renderGroup = (group: Group, index: number, fieldClassName?: string) => {
    return (
      <Grid item key={'group_' + index}>
        <fieldset className={classes.fieldSet}>
          {group.label && (
            <legend>
              <Typography variant="h4" component="h3">
                <FormattedMessage id={group.label} />
              </Typography>
            </legend>
          )}

          {renderGrid(group.grid, fieldClassName)}
        </fieldset>
      </Grid>
    );
  };

  const renderField = (field: FormFieldState, className?: string) => {
    const [state, setState] = field.state;
    const errorTexts = hasErrors(field.name);
    const props = {
      name: field.name,
      placeholder: field.placeholder,
      label: field.label,
      required: field.required,
      error: errorTexts.length > 0,
      errorTexts: errorTexts,
      onChange: setState,
      ref: field.formFieldRef,
      initialState: state,
      hideRequiredAsterisk: field.hideRequiredAsterisk,
      hidden: field.fieldRequirements && field.fieldRequirements.some((req) => req.state[0] === undefined),
      disable:field.disable,
    };
    return (
      <Grid className={className} style={{ flex: 1 }} item sm xs={12}>
        {!props.hidden && (
          <>
            {field.type === 'readonly' && (
              <TextField
                {...props}
                readOnly
                required={false}
                multiline={field.multilineRows ? true : false}
                rows={field.multilineRows}
              />
            )}

            {field.type === 'text' && (
              <TextField {...props} multiline={field.multilineRows ? true : false} rows={field.multilineRows} />
            )}
            {field.type === 'email' && <EmailField {...props} />}
            {field.type === 'phoneNumber' && <PhoneNumberField {...props} />}
            {field.type === 'postalCode' && <PostalCodeField {...props} />}
            {(field.type === 'integer' || field.type === 'decimal') && (
              <NumberField {...props} isInt={field.type === 'integer'} icon={field.icon} isDisabled={field.disable} />
            )}
            {field.type === 'single-select' && field.options && (
              <SelectField {...props} isClearable={!field.required} options={field.options} icon={field.icon} onhandle={(value:any) => {
                if (field.checkedValue) {
                field.checkedValue(value);
                }
              }}  />
            )}
            {field.type === 'multi-select' && field.options && (
              <SelectField {...props} isMulti isClearable={!field.required} options={field.options} />
            )}
            {field.type === 'single-select-async' && field.loadOptions && (
              <AsyncSelectField
                {...props}
                defaultOptions
                placeholder={props.placeholder || intl.formatMessage({ id: 'utils.select.typeToSearch' })}
                isClearable={!field.required}
                loadOptions={(search: string) => {
                  if (field.loadOptions) {
                    return field.loadOptions(search);
                  }
                }}
              />
            )}
            {field.type === 'single-select-card' && field.options && (
              <SelectCard label={field.label} icon={field.icon}>
                <SelectField
                  {...props}
                  card
                  options={field.options}
                  onChange={field.state[1]}
                  isClearable={!field.required}
                />
              </SelectCard>
            )}
            {field.type === 'radio-select' && field.options && (
              <RadioField
                {...props}
                options={field.options.map((x) => ({ value: x.value, label: x.label }))}
                onChange={field.state[1]}
              />
            )}
            {field.type === 'date' && <DateField {...props} isDisabled={field.disable}/>}
            {field.type === 'datetime' && <DateTimeField {...props} isDisabled={field.disable} />}

            {field.type === 'rich-text' && <RichTextField {...props} height={field.richTextEditorHeight} />}
            {field.type === 'checkbox' && <CheckboxField {...props} onhandle={(value) => {
              if (field.checkedValue) {
              field.checkedValue(value);
              }
            }} />}
          </>
        )}
      </Grid>
    );
  };

  const renderGrid = (grid: ItemGrid, index: string | undefined, fieldClassName?: string) => {
    return (
      <Grid style={{ flex: grid.flex ? grid.flex : 1}} xs={12} item key={`grid_${index}`}>
        <Grid container direction={grid.type} className={classes.grid} spacing={4} wrap="nowrap">
          {grid.items.map((item, itemIndex) => {
            const type = getItemType(item);
            if (type === 'grid') {
              return (
                <React.Fragment key={`${index}_${itemIndex}`}>
                  {renderGrid(item as ItemGrid, `${index}_${itemIndex}`)}
                </React.Fragment>
              );
            } else if (type === 'field') {
              return (
                <React.Fragment key={`${index}_${itemIndex}`}>
                  {renderField(item as FormFieldState, fieldClassName)}
                </React.Fragment>
              );
            } else {
              return <Void key={`${index}_${itemIndex}`} />;
            }
          })}
        </Grid>
      </Grid>
    );
  };

  const isSingle = singleField !== undefined;

  return (
    <form className={classes.root} onSubmit={handleSubmit} {...rest}>
      <Grid container spacing={isSingle ? 2 : 4} direction={isSingle ? 'row' : 'column'} wrap="nowrap">
        {groups && groups.map((group, index) => renderGroup(group, index))}
        {singleField && renderField(singleField, classes.singleField)}
        <Grid item>
          <Grid container justify="flex-end" spacing={2}>
            {cancel && (
              <Grid item>
                <Button
                  color="secondary"
                  disabled={loading}
                  className={isSingle ? classes.singleFieldButton : undefined}
                  onClick={cancel}
                >
                  {cancelLabel || intl.formatMessage({ id: 'form.cancel' })}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                variant="outlined"
                disabled={!validForm || loading||hideButton}
                className={isSingle ? classes.singleFieldButton : undefined}
                type="submit"
              >
                {(loading && <CircularProgress size="1.5rem" />) ||
                  submitLabel ||
                  intl.formatMessage({ id: 'form.save' })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
