import { Card, CardContent, CardHeader, Grid, Typography, Button, Tooltip } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Panel, BackButton } from '../../../../../../Components';
import { LawChange, IChange } from '../../../../../../Components/LawChange';
import { UnacknowledgedChanges } from './Unacknowledged';
import { LatestChange } from './LatestChange';
import { AccessLevel, LawPortalFeature, UserContext } from '../../../../../../Context/UserContext/UserContext';
import { IListResult } from '../../../../../../Api/AptorApi';
import { useAptorApi } from '../../../../../../Api';
import { ArrowUpward } from '@material-ui/icons'
import { useStyles } from "../../../../LawPortal.styles";


export interface ILawChange extends IChange {
  unacknowledgedByUsers: number[];
}

const LawChanges = () => {
  const match = useRouteMatch<{ id: string }>();
  const id = parseInt(match.params.id);
  const { api, abortController } = useAptorApi();
  const user = useContext(UserContext);
  const displayUnacknowledgedChangesPanel = user.hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
  const [revision, setRevision] = useState<number>(0);
  const [state, setState] = useState<IListResult<ILawChange>>();
  const classes = useStyles()
  const history=useHistory()
  const {formatMessage}=useIntl()
  useEffect(() => {
    api.getLawPortalLawChanges<ILawChange>(id).then((state) => {
      if (abortController.current.signal.aborted) {
        return;
      }
      setState(state);
    });
  }, [api, abortController, id]);

  const handleAcknowledge = async (changeId: number, comment?: string) => {
    if (state?.items) {
      await api.acknowledgeLawPortalLawChange(id, changeId, comment);
      if (abortController.current.signal.aborted) {
        return;
      }
      const items = [...state.items].replace(
        (c) => c.id === changeId,
        (change) => ({
          ...change,
          ...{ unacknowledgedByUsers: change.unacknowledgedByUsers.filter((x) => x !== user.id) },
        }),
      );
      setState({ ...state, ...{ items } });
      setRevision(revision + 1);
    }
  };

  const latestChange = state?.items[0] ?? undefined;
  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Grid item xs={12}>
        {/* <BackButton disableMargin link="/law-portal/our-laws/" /> */}
        <BackButton disableMargin link="/law-portal/our-laws/" />
      </Grid>
      <Grid item md={9} xs={12}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Panel titleKey="law-portal.our-laws.changes.latest-change.label">
              {latestChange ? (
                <>
                  <Typography variant="h4">{latestChange.number}</Typography>
                  <LawChange
                    lawId={id}
                    change={latestChange}
                    acknowledge={(comment?: string) => handleAcknowledge(latestChange.id, comment)}
                  />
                </>
              ) : (
                <FormattedMessage id="law-portal.our-laws.changes.no-changes.message" />
              )}
            </Panel>
          </Grid>
          {state && state.items.length > 1 && (
            <Grid item xs={12}>
              <Panel titleKey="law-portal.our-laws.changes.older-changes.label">
                {state.items
                  .filter((_, index) => index !== 0)
                  .map((change) => (
                    <Card variant="outlined" key={change.id}>
                      <CardHeader title={<Typography variant="h4">{change.number}</Typography>} />
                      <CardContent>
                        <LawChange lawId={id} change={change} acknowledge={(comment?: string) => handleAcknowledge(change.id,comment)} />
                      </CardContent>
                    </Card>
                  ))}
              </Panel>
            </Grid>
          )}
          {displayUnacknowledgedChangesPanel && (
            <Grid item xs={12}>
              <UnacknowledgedChanges lawId={id} key={revision} />
            </Grid>
          )}
        </Grid>
      </Grid>
      {showScrollTop && (
        <Tooltip title={formatMessage({id:"bottom.to.top.btn.tooltip.text",defaultMessage:"Back to top"})}>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className={classes.topButton}
          >
            <ArrowUpward style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      )}

    </>
  );
};

export { LawChanges, LatestChange, UnacknowledgedChanges };
