import { makeStyles } from '@material-ui/core';

export const useStyles: any = makeStyles((theme: any) => ({
  listWrapper: {
    width: '100%',
    position: 'relative',
  },
  topButton: {
    borderRadius: ' 50%',
    position: 'fixed',
    bottom: '10px',
    transform: 'translateX(-44px)',
    justifyContent: 'center',
    alignItems: 'center',
    right: 0,
    display: 'flex',
    //height: '46px',
    opacity: .7,
    width: 50,
    height: 50,
    zIndex: 1111
  },
  root: {
    minHeight: '100%',
  },
  autoHeight: {
    minHeight: 'auto',
  },
  panelDetails: {
    display: 'block',
  },
  text: {
    textTransform: "none"
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '25px'
  },
  tab: {
    padding: "10px 20px",
    display: "flex",
     gap: "5px",
    alignItems: 'center',
    background:'rgb(253,253,253)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: "rgb(208,208,208)"
  },
  tabActive: {
    padding: "10px 20px",
    display: "flex",
     gap: "5px",
    alignItems: 'center',
    background:'rgb(253,253,253)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: "rgb(208,208,208)",
    borderBottomColor:"rgb(198,18,47)",
    borderBottomWidth:'3px'

  },
  tabNumber: {
    borderRadius: "12px",
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: "rgb(208,208,208)",
    paddingLeft: "7px",
    paddingRight: "7px"
  }
}));
