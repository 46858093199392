import { Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { Panel } from '../../../../../../Components';
import { LawChange } from '../../../../../../Components/LawChange';
import { UserContext } from '../../../../../../Context/UserContext/UserContext';
import { useAptorApi } from '../../../../../../Api';
import { ILawChange } from '.';

interface IProps {
  lawId: number;
  change: ILawChange;
  onChange: (state: { latestChange: ILawChange }) => void;
}

export const LatestChange: FC<IProps> = ({ lawId, change, onChange }) => {
  const { api, abortController } = useAptorApi();
  const { id } = useContext(UserContext);
  const handleAcknowledge = async (comment?: string) => {
    await api.acknowledgeLawPortalLawChange(lawId, change.id, comment);
    if (abortController.current.signal.aborted) {
      return;
    }
    const latestChange = {
      ...change,
      ...{ unacknowledgedByUsers: change.unacknowledgedByUsers.filter((userId) => userId !== id) },
    };
    onChange({ latestChange });
  };

  return (
    <Panel titleKey="law-portal.our-laws.changes.latest-change.label">
      <Typography variant="h4">{change.number}</Typography>
      <LawChange lawId={lawId} change={change} acknowledge={handleAcknowledge} />
    </Panel>
  );
};
