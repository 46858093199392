import React, { useContext, useEffect, useState } from 'react';
import { useStyles } from '../../../LawPortal.styles';
import {
  ButtonLink,
  MTable,
  //  MTable,
  //   Panel,
  //    RepealableName,
  Transition
} from '../../../../../Components';
import { useIntl } from 'react-intl';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Tooltip,
  //  IconButton,
  // LinearProgress,
  // Theme,
  // Tooltip,
  //  Typography,
  // createStyles,
  // withStyles
} from '@material-ui/core';
import {
  // ArrowForward,
  ArrowRightAlt,
  DoneOutline,
  Edit,
  // ControlPointDuplicate,
  //  Delete,
  //   DoneOutline,
  //    Edit,
  ExpandMore,
  //  PlaylistAdd,
  //   SyncDisabledSharp,
  //    SyncSharp
} from '@material-ui/icons';
import { CreateDirectCompliance } from "./CreateDirectCompliance";
import { ModuleContext } from '../../../../../Context/ModuleContext/ModuleContext';
import {
  Module,
  // PlanFeature,
  UserContext
} from "../../../../../Context/UserContext/UserContext";
import { useAptorApi } from "../../../../../Api";
import { Title } from "../../../../../Components/Panel/Panel";
import PlanningViewLK from "./View/PlanningViewLK";
import moment from "moment";
import { useComplianceState } from "./List/ComplianceState";
import { ExecuteFilter } from "./ExecuteFilter";
import { useFilter } from "../../../../../Components/ListFilter";
import { useHistory, useLocation } from "react-router-dom";
import { MTableToolbar } from 'material-table';
// import async from "react-select/async";
// import async from "react-select/async";
// import { Link } from "react-router-dom";
// import { useComplianceSession } from "../../../../../Context/ComplianceSessionContext";
// import { PublishedWithChanges, Unpublished } from '@mui/icons-material';
interface IconProps {
  id: number;
  icon?: React.ReactNode;
  onClick?: () => void;
}
interface BorderLinearProgressProps {
  name: string; // Define the type of the name prop
  variant: 'determinate' | 'indeterminate' | 'buffer' | 'query';
  value: number;
}
export const CarryLK = () => {
  const {
    // enableCompliance,
    // complianceEnabled,
    // disableCompliance,
    startComplianceSession,
    // hasCompliance,
    // isComplianceUserFor,
    // toggleCompliance,
    setLawsSelectedForCompliance,
    toggleCompliance,
    setPlan,
    setPLanEdit
  } = useComplianceState();
  // const {
  //   clearComplianceSession,
  //   complianceSession
  // } = useComplianceSession();
  // const tableRef = React.createRef();
  const classes = useStyles();
  const [createvisible, setcreateVisible] = useState(false);
  const { customerId } = useContext(UserContext)
  const { api, abortController } = useAptorApi();
  const [directPlanLoading, setDirectPlanLoading] = useState(false)
  const [planData, setPlanData] = useState([])
  const [directPlan, setDirectPlanData] = useState([])
  const [expendDirectPlanPanel, setExpendDirectPlanPanel] = useState("")
  const [expendPlanPanel, setExpendPlanPanel] = useState("")
  let { formatMessage } = useIntl();
  const { setHeader } = useContext(ModuleContext);
  const { hasAccessToModule } = useContext(UserContext);
  const [filters, setFilter] = useFilter();
  const [search, setSearch] = useState("")
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [allcontrol, setAllControl] = useState([])
  const history = useHistory()
  useEffect(() => {
    var menu = [
      {
        title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.carry.lek' }),
        to: `/law-portal/legal-compliance-control/carry`,
      },
      {
        title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.reports.lek' }),
        to: `/law-portal/legal-compliance-control/reports`,
      },
    ]
    if (hasAccessToModule(Module.Administration)) {
      menu = [
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.planning.lek' }),
          to: `/law-portal/legal-compliance-control/planning`,
        },
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.carry.lek' }),
          to: `/law-portal/legal-compliance-control/carry`,
        },
        {
          title: formatMessage({ id: 'law-portal.legal.compliance.sub-menu.reports.lek' }),
          to: `/law-portal/legal-compliance-control/reports`,
        },
      ]
    }
    setHeader(formatMessage({ id: 'law-portal.legal.compliance.header', defaultMessage: 'Legal compliance control' }), menu);
  }, [setHeader, formatMessage, expendPlanPanel, expendDirectPlanPanel]);
  useEffect(() => {
    const queryParamsMap = {
      tags: 'tags',
      companyUnit: 'companyUnit',
      complianceUsers: 'complianceUsers',
      processes: 'processes',
      aspects: 'aspects',
      category: 'category',
      area: 'area',
    };
    const searchQuery = queryParams.getAll('search')
    if (searchQuery.length) {
      setSearch(searchQuery[0])
    }
    Object.entries(queryParamsMap).forEach(([queryKey, stateKey]) => {
      const queryValues = queryParams.getAll(queryKey);
      if (queryValues.length) {
        setFilter(stateKey, queryValues.join(',').split(',').map(num => num.toString()));
      }
    });
  }, []);
  // const editPlane = () => {
  //   alert('edit plane');
  // };
  const [startCompliance, setStartCompliance] = useState(false)
  const getplanLaws = async (planData: any) => {
    await api.searchdirectPlanLaws(planData.planID.id).then((response: any) => {
      setPlan(planData?.planID)
      setLawsSelectedForCompliance(response)
      setStartCompliance(true)
    })
  }
  const getPlanRequirement = async (planData: any) => {
    await api.getPlanRequirement(planData.planID.id).then((response: any) => {
      setPlan(planData?.planID)
      setLawsSelectedForCompliance(response)
      setStartCompliance(true)
    })
  }
  const handleComplianceSession = async (event: any, planData: any) => {
    event.stopPropagation();
    if (planData?.planID?.type === 1) {
      await getPlanRequirement(planData)
    } else {
      await getplanLaws(planData)
    }
    setStartCompliance(false)
  }
  useEffect(() => {
    startComplianceSession()
  }, [startCompliance])

  const IconsNode = (planData: any) => {
    // const nextLawInCompliance: any = complianceSession?.next();
    return (
      <>
        <Grid>
          <Button variant="contained" type="submit" onClick={(event: any) => handleComplianceSession(event, planData)} >
            <ArrowRightAlt />
            {/* {planData?.planID?.type === 1 ? formatMessage({ id: 'law-portal.compliance.tab.carryout.lek.panel.btn.name' }) : formatMessage({ id: "law-portal.compliance.tab.carryout.panel.btn.name" })} */}
            {formatMessage({ id: "law-portal.compliance-law.execute.evaluation-for-all" })}

          </Button>
        </Grid>
        {/* <Grid item>
          <Button startIcon={<ArrowForward />} component={Link} to={nextLawInCompliance?.complianceUrl}>
            <FormattedMessage
              id="law-portal.our-laws.table.continue-compliance-session.button"
              defaultMessage="Continue compliance session"
            />
          </Button>
        </Grid> */}
      </>
    );
  };

  // const BorderLinearProgress = withStyles((theme: Theme) =>
  //   createStyles({
  //     root: {
  //       height: 25,
  //       borderRadius: 10,
  //       boderWidth: '3px',
  //       borderColor: 'red',
  //       borderStyle: 'solid',
  //     },
  //     colorPrimary: {
  //       backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  //     },
  //     bar: {
  //       borderRadius: 10,
  //       // Use the name prop to set the background color of the bar
  //       backgroundColor: (props: BorderLinearProgressProps) =>
  //         props.name === 'blue' ? theme.palette.grey[theme.palette.type === 'light' ? 200 : 700] : 'green',
  //     },
  //   }),
  // )(({ name, ...props }: BorderLinearProgressProps) => <LinearProgress {...props} />);
  useEffect(() => {
    getComplianceDirectPlan()
  }, [filters,search])
  const getComplianceDirectPlan = async () => {
    setDirectPlanLoading(true);
    await api.getAllcompliancePlan(customerId,{filters:filters,search:search}).then((response: any) => {
      if (!abortController.current.signal.aborted) {
        const plan = response?.customerPlans?.filter((val: any) => !val.isDirect && val.isActive)
        const directPlan = response?.customerPlans?.filter((val: any) => val.isDirect)
        plan?.sort((a: any, b: any) => {
          const startDateComparison = new Date(b.startDate).getTime() as number - new Date(a.startDate).getTime() as number
          if (startDateComparison === 0) {
            return a.name.localeCompare(b.name);
          }

          return startDateComparison;
        });
        directPlan?.sort((a: any, b: any) => {
          const startDateComparison = new Date(b.startDate).getTime() as number - new Date(a.startDate).getTime() as number
          if (startDateComparison === 0) {
            return a.name.localeCompare(b.name);
          }

          return startDateComparison;
        });
        setPlanData(plan)
        setDirectPlanData(directPlan)
        setDirectPlanLoading(false);
      }
    }).catch(() => {
      setDirectPlanLoading(false);
    })
  }
  const handleDirectPlanPanelToggle = (panelId: string) => {
    // Toggle the expansion state of the panel with the given panelId.
    if (expendDirectPlanPanel === panelId) {
      setExpendDirectPlanPanel("");
    } else {
      setExpendDirectPlanPanel(panelId);
    }
  };
  const handlePlanPanelToggle = (panelId: string) => {
    // Toggle the expansion state of the panel with the given panelId.
    if (expendPlanPanel === panelId) {
      setExpendPlanPanel("");
    } else {
      setExpendPlanPanel(panelId);
    }
  };

  useEffect(() => {
    var itemCount = localStorage.length
    for (var i = 0; i < itemCount; i++) {
      var key: any = localStorage.key(i);
      if (key) {
        const checkCompliance = key.split(/[_-]/)[0]
        if (checkCompliance === "compliance" || checkCompliance === "ComplianceSession") {
          localStorage.removeItem(key)
        }
      }
    }
  }, [])
  useEffect(() => {
    setExpendDirectPlanPanel("")
    setExpendPlanPanel("")
  }, [filters, search])
  useEffect(() => {
    var updatedQueryParams = Object.keys(filters)
      .filter(key => filters[key]?.join() !== undefined && filters[key]?.join() !== "")
      .map(key => `${key}=${encodeURIComponent(filters[key]?.join())}`)
      .join('&');
    if (search !== "") {
      updatedQueryParams = `${updatedQueryParams}&search=${search}`
    }
    history.push({
      pathname: '',
      search: `?${updatedQueryParams}`,
    });
  }, [filters, history, search]);
  const columnplan = [
    { title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.name-of-control' }), field: 'name' },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Company-unit' }),
      field: 'customerCompanyUnitId',
      render: (row: any) => row?.customerCompanyUnitId?.name,
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Start-date' }),
      field: 'startDate',
      render: (row: any) => moment(row?.startDate).format('YYYY-MM-DD'),
      sorting: false,
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.End-date' }), field: 'endDate',
      render: (row: any) => row?.endDate ? moment(row?.endDate).format('YYYY-MM-DD') : "-", sorting: false,
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Recuring-interval' }),
      field: 'recurrentInterval',
      render: (row: any) => row?.recurrentInterval || "-",
      sorting: false,
    },
    {
      title: formatMessage({
        id: 'utils.filters.status',
      }),
      field: 'status',
      render: (row: any) => `${row.executedLawCount} ${formatMessage({ id: 'text.of' })} ${row.lawCount} ${formatMessage({ id: 'status.text' })} `,
      sorting: false,
    },
    {
      title: "",
      field: '',
      render: (row: any) => <>
        <IconsNode planData={row} />
      </>,
      sorting: false,
    },
  ]
  
     const { firstName, lastName } = useContext(UserContext);
    const currentuserName = `${firstName} ${lastName}`

    const handleComplianceSessionnew = async (event: any, lawData: any) => {
      let lawDatanew={planid:lawData.id,...lawData}
      lawDatanew.id=lawData?.legislationId
      console.log(lawDatanew,"dkndkdnkd")
      setStartCompliance(true)
        event.stopPropagation();
        setPlan(lawData)
        await toggleCompliance(lawDatanew)
        setStartCompliance(false)
  }
  const handleEditSession = async (lawData: any) => {
    let lawDatanew={planid:lawData.id,...lawData}
      lawDatanew.id=lawData?.legislationId
    setStartCompliance(true)
    setPlan(lawData)
    setPLanEdit(true)
    await toggleCompliance(lawDatanew)
    setStartCompliance(false)
  }
  const allplan :any= [
    { title: formatMessage({ id: 'law-portal.our-laws.table.number' }), field: 'number',sorting: false},
    {
      title: formatMessage({ id: 'law-portal.our-laws.table.law' }),
      field: 'legislation',
      sorting: false, 
      
    },
    { title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.name-of-control' }), field: 'name' ,sorting: false},
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Company-unit' }),
      field: 'customerCompanyUnitId',
      render: (row: any) => row?.customerCompanyUnitId?.name,
      sorting: false
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Start-date' }),
      field: 'startDate',
      render: (row: any) => moment(row?.startDate).format('YYYY-MM-DD'),
      sorting: false
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.End-date' }), field: 'endDate',
      render: (row: any) => moment(row?.endDate).format('YYYY-MM-DD'), sorting: false
    },
    {
      title: formatMessage({ id: 'law-portal.compliance-law.plan-compliance.Recuring-interval' }),
      field: 'recurrentInterval',
      render: (row: any) => row?.recurrentInterval || "-",
      sorting: false
    },
    {
      title: formatMessage({ id: 'law-portal.compliance.report.table.col' }),
      field: 'responsibles',
      render: (row: any) => {
        if (row.responsibles) {
          const responsiblearr = row?.responsibles?.split(',')
          return (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {responsiblearr?.map((val: any) => {
                  return <span>{val}</span>
                })}
              </div>
            </>
          )
        } else {
          return "-"
        }
      },
      sorting: false,
      width:"20%"
    },
    {
      title: '',
      field: '',
      render: (row:any) => {
          const responsible = row?.responsibles?.split(',')
          responsible?.forEach((item: any, index: any, array: any) => {
              array[index] = item.toLowerCase();
          });
          return (
              <>
                  {responsible?.includes(currentuserName.toLowerCase()) ? row.isStarted ?
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '7px' }}>
                          <Tooltip title={formatMessage({ id: "law-portal.our-law.compliance.complete" })}>
                              <DoneOutline style={{ cursor: 'pointer' }} />
                          </Tooltip>
                          <Tooltip title={formatMessage({ id: "law-portal.our-law.compliance.edit" })}>
                              <Edit style={{ cursor: 'pointer' }} onClick={() => handleEditSession(row)} />
                          </Tooltip>
                      </div>
                      : <Button
                          variant="contained"
                          onClick={(event: any) => handleComplianceSessionnew(event, row)}
                      >
                          {row?.type === 1 ? formatMessage({ id: "law-portal.our-laws.compliance.tab.carryout.kek.create.button" }) : formatMessage({ id: "law-portal.our-laws.compliance.tab.carryout.create.button" })}
                      </Button> : ""}
              </>
          );
      },
  },
  ]
  const tableRef = React.createRef();
  const [column, setColumn] = useState(columnplan)
  const [activeTab, setActiveTab] = useState<any>('ongoing-plan')

  const handleAllControl = async () => {
    const allcompliance: any = await api.getAllcompliancePlanAllControl<any>(customerId,{ search: search,
      filters: filters});

    setAllControl(allcompliance)
  }

  useEffect(() => {
    handleAllControl()
  }, [filters,search])
  const handleAll = () => {
    setColumn(allplan)
    setActiveTab('all')
  }
  return (
    <div className={classes.listWrapper}>
      {createvisible ?
        <ButtonLink text={formatMessage({ id: "component.back-button.back" })} to={''} onclick={() => setcreateVisible(false)} />
        :
        <ButtonLink text={formatMessage({ id: "law-portal.legal.complaince.create.new.compliance.direct" })} to={''} onclick={() => setcreateVisible(true)} />
      }
      <hr style={{ marginTop: '25px', marginBottom: "25px" }} />
      {createvisible ?
        <CreateDirectCompliance getComplianceDirectPlan={getComplianceDirectPlan} handleAllControl={handleAllControl} setcreateVisible={setcreateVisible} />
        :
        <>
          <ExecuteFilter filters={filters} setFilter={setFilter} search={search} setSearch={(value: any) => setSearch(value)} />
          <hr style={{ marginTop: '25px', marginBottom: "25px" }} />
          <Transition isLoading={directPlanLoading}>
            {activeTab == 'all' ?
              <MTable
                tableRef={tableRef}
                columns={column}
                // data={updateLaws !== "" && updateLaws == planLawsData.id?handleDataFetch:getPlanlaws}
                data={allcontrol}
                options={{
                  detailPanelColumnAlignment: 'right'
                }}
                components={{
                  Toolbar: (props) => <div className={classes.toolbar} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex' }}>
                      <div className={activeTab === "ongoing-plan" ? classes?.tabActive : classes?.tab} onClick={() => {
                        setActiveTab('ongoing-plan')
                        setColumn(columnplan)
                      }}>{formatMessage({ id: 'law-portal.compliance.tab.carryout.heading.continuously' })}<span className={classes?.tabNumber}>{planData?.length}</span></div>
                      <div className={activeTab === "ongoing-direct" ? classes?.tabActive : classes?.tab} onClick={() => {
                        setActiveTab('ongoing-direct')
                        setColumn(columnplan)
                      }}>{formatMessage({ id: 'law-portal.compliance.tab.carryout.heading.ongoing' })}<span className={classes?.tabNumber}>{directPlan?.length}</span></div>
                      <div className={activeTab == "all" ? classes?.tabActive : classes?.tab} onClick={()=>handleAll()} >{formatMessage({ id: 'law-portal.compliance-law.plan-compliance.all-control' })}<span className={classes?.tabNumber}>{allcontrol?.length}</span></div>

                    </div>
                    <MTableToolbar {...props} />

                  </div>
                }}
              /> :
              <MTable
                tableRef={tableRef}
                columns={column}
                // data={updateLaws !== "" && updateLaws == planLawsData.id?handleDataFetch:getPlanlaws}
                data={activeTab == "ongoing-plan" ? planData : directPlan}
                options={{
                  detailPanelColumnAlignment: 'right'
                }}
                components={{
                  Toolbar: (props) => <div className={classes.toolbar} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex' }}>
                      <div className={activeTab == "ongoing-plan" ? classes?.tabActive : classes?.tab} onClick={() => {
                        setActiveTab('ongoing-plan')
                        setColumn(columnplan)
                      }}>{formatMessage({ id: 'law-portal.compliance.tab.carryout.heading.continuously' })}<span className={classes?.tabNumber}>{planData?.length}</span></div>
                      <div className={activeTab == "ongoing-direct" ? classes?.tabActive : classes?.tab} onClick={() => {
                        setActiveTab('ongoing-direct')
                        setColumn(columnplan)
                      }}>{formatMessage({ id: 'law-portal.compliance.tab.carryout.heading.ongoing' })}<span className={classes?.tabNumber}>{directPlan?.length}</span></div>
                      <div className={activeTab == "all" ? classes?.tabActive : classes?.tab} onClick={()=>handleAll()} >{formatMessage({ id: 'law-portal.compliance-law.plan-compliance.all-control' })}<span className={classes?.tabNumber}>{allcontrol?.length}</span></div>

                    </div>
                    <MTableToolbar {...props} />

                  </div>
                }}
                detailPanel={(rowData: any) => {
                  return <PlanningViewLK planData={rowData} filters={filters} search={search} />
                }}
/>

        }
            {/* <Grid container spacing={4} direction="column" justify="space-evenly">
              <Grid>
                <h2 style={{ marginLeft: 20 }}>
                  {formatMessage({ id: 'law-portal.compliance.tab.carryout.heading.continuously' })}
                </h2> */}
            {/* <h3 style={{ marginTop: 0, marginLeft: 20 }}>{`${formatMessage({
                  id: 'utils.filters.status',
                })}: ${10} ${formatMessage({ id: 'text.of' })} ${15} ${formatMessage({ id: 'status.text' })} `}</h3> */}
            {/* </Grid>
              <Grid style={{ display: "flex", flexDirection: "column", gap: '15px' }} item>
                {
                  planData?.map((val: any, index: number) => {
                    return (
                      <div key={index}>
                        <ExpansionPanel defaultExpanded={false} expanded={expendPlanPanel === val.id} onChange={() => handlePlanPanelToggle(val.id)} >
                          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                            <Grid container justify='space-between' alignItems='center'>
                              <Title title={formatMessage({ id: "default", defaultMessage: `${moment(val.startDate).format("YYYY-MM-DD")} -- ${moment(val.endDate).format("YYYY-MM-DD")} ${val.name}` })} />
                              <Grid>
                                {val.lawCount > 0 && val.isExecutionVisible && <IconsNode planID={val} />}
                              </Grid>
                            </Grid>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails className={classes.panelDetails}>
                            <h3 style={{ marginTop: 0, marginLeft: 20 }}>{`${formatMessage({
                              id: 'utils.filters.status',
                            })}: ${val.executedLawCount} ${formatMessage({ id: 'text.of' })} ${val.lawCount} ${formatMessage({ id: 'status.text' })} `}</h3>
                            {expendPlanPanel === val.id && <PlanningViewLK planData={val} filters={filters} search={search} />}
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </div>
                    )
                  })
                }
              </Grid>
            </Grid>
            <hr style={{ marginTop: '25px', marginBottom: '25px' }} />
            <Grid container spacing={4} direction="column" justify="space-evenly">
              <Grid>
                <h2 style={{ marginLeft: 20 }}>
                  {formatMessage({ id: 'law-portal.compliance.tab.carryout.heading.ongoing' })}
                </h2> */}
            {/* <h3 style={{ marginTop: 0, marginLeft: 20 }}>{`${formatMessage({
                  id: 'utils.filters.status',
                })}: ${10} ${formatMessage({ id: 'text.of' })} ${15} ${formatMessage({ id: 'status.text' })} `}</h3> */}
            {/* </Grid>
              <Grid style={{ display: "flex", flexDirection: "column", gap: '15px' }} item>
                {
                  directPlan?.map((val: any, index: number) => {
                    return (
                      <div key={index}>
                        <ExpansionPanel defaultExpanded={false} expanded={expendDirectPlanPanel === val.id} onChange={() => handleDirectPlanPanelToggle(val.id)} >
                          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                            <Grid container justify='space-between' alignItems='center'>
                              <Title title={formatMessage({ id: "default", defaultMessage: `${moment(val.startDate).format("YYYY-MM-DD")} -- ${val.EndDate ? moment(val.endDate).format("YYYY-MM-DD") : ""} ${val.name}` })} />
                              <Grid>
                                {val.lawCount > 0 && val.isExecutionVisible && <IconsNode planID={val} />}
                              </Grid>
                            </Grid>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails className={classes.panelDetails}>
                            <h3 style={{ marginTop: 0, marginLeft: 20 }}>{`${formatMessage({
                              id: 'utils.filters.status',
                            })}: ${val.executedLawCount} ${formatMessage({ id: 'text.of' })} ${val.lawCount} ${formatMessage({ id: 'status.text' })} `}</h3>
                            {expendDirectPlanPanel === val.id && <PlanningViewLK planData={val} filters={filters} search={search} />}
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </div>
                    )
                  })
                }
              </Grid>
            </Grid> */}
          </Transition>
        </>
      }
    </div>
  );
}
