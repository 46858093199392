import React, { useEffect, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Grid, Tooltip } from '@material-ui/core';
import { Panel, Container } from '../../../../Components';
import { HelpPanel } from './HelpPanel';
import { ModuleContext } from '../../../../Context/ModuleContext/ModuleContext';
import { ArrowUpward } from '@material-ui/icons';
import { useStyles } from '../../LawPortal.styles';
export const Help = () => {
  const { setHeader } = useContext(ModuleContext);
  const { formatMessage } = useIntl();
  const classes = useStyles();
  useEffect(() => {
    setHeader(formatMessage({ id: 'law-portal.settings.header', defaultMessage: 'Settings' }));
  }, [setHeader, formatMessage]);
  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Container>
        <Grid container spacing={4} direction="column" justify="space-evenly">
          <Grid item>
            <Panel titleKey="law-portal.settings.help" accordion>
              <HelpPanel />
            </Panel>
          </Grid>
        </Grid>
        {showScrollTop && (
        <Tooltip
        title={formatMessage({id:"bottom.to.top.btn.tooltip.text",defaultMessage:"Back to top"})}>
        <Button
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          className={classes.topButton}
        >
          <ArrowUpward style={{fontSize:20}}/>
        </Button>
        </Tooltip>
      )}
      </Container>
    </>
  );
};
