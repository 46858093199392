import { saveAs } from 'file-saver';
import { getAppVersion } from '../../Config/Environment';

interface IRequestOptions {
  responseHandler: (response: Response) => Promise<any>;
}

export class Api {
  private serviceBase: string;
  public abortController?: AbortController;

  private jsonHeader = { 'Content-Type': 'application/json','Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
  private exportHeader = { 'Content-Type': 'application/json','Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
  constructor(baseAddress?: string, abortController?: AbortController) {
    this.serviceBase = (baseAddress || window.location.origin) + '/api';
    this.abortController = abortController;
  }

  public setAbortController = (abortController: AbortController) => {
    this.abortController = abortController;
  };

  public static emptyResponseHandler = (response: Response) => Promise.resolve();

  public async get(url: string, q: any = {}, options?: IRequestOptions): Promise<any> {
    const response = await this.request('GET', url, q, undefined);
    return (options && options.responseHandler(response)) || response.json();
  }
  public async gets(url: string, q: any = {}, options?: IRequestOptions): Promise<any> {
    const response = await this.requestExport('GET', url, q, undefined);
    return response;
  }

  public async post(url: string, data: any = {}, ignoreResponse?: boolean, options?: IRequestOptions): Promise<any> {
    const response = await this.request('POST', url, undefined, data);
    if (ignoreResponse) {
      return;
    }
    return (options && options.responseHandler(response)) || response.json();
  }

  public async upload(url: string, data?: File, ignoreResponse?: boolean, options?: IRequestOptions): Promise<any> {
    const response = await this.request('POST', url, undefined, data, true);
    if (ignoreResponse) {
      return;
    }
    return (options && options.responseHandler(response)) || response.json();
  }

  public async put(
    url: string,
    data: any = {},
    ignoreResponse?: boolean,

    options?: IRequestOptions,
  ): Promise<any> {
    const response = await this.request('PUT', url, undefined, data);
    if (ignoreResponse) {
      return;
    }
    return (options && options.responseHandler(response)) || response.json();
  }

  public async delete(
    url: string,
    data: any = {},
    ignoreResponse?: boolean,

    options?: IRequestOptions,
  ): Promise<any> {
    const response = await this.request('DELETE', url, undefined, data);
    if (ignoreResponse) {
      return;
    }
    return (options && options.responseHandler(response)) || response.json();
  }

  public async getDownload(url: string, q: any = {}): Promise<void> {
    const response = await this.request('GET', url, q);
    const blob = await response.blob();
    saveAs(blob, this.extractHeaderFilename(response));
  }

  public async getBlobUrl(url: string, q: any = {}): Promise<string | undefined> {
    const response = await this.request('GET', url, q);
    const blob = await response.blob();
    if (blob) {
      return URL.createObjectURL(blob);
    } else {
      return undefined;
    }
  }

  public async getBlobBase64(url: string, q: any = {}): Promise<string | undefined> {
    const response = await this.request('GET', url, q);
    const blob = await response.blob();
    if (blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result as string);
        };
      });
    } else {
      return undefined;
    }
  }

  public async postDownload(url: string, data: any = {}): Promise<void> {
    const response = await this.request('POST', url, undefined, data);
    const blob = await response.blob();
    saveAs(blob, this.extractHeaderFilename(response));
  }

  /* Generic request method */
  protected async request(
    method: string,
    url: string,
    q: any = {},
    data?: any,
    dataIsFile: boolean = false,
  ): Promise<Response> {
    let queryString = this.buildQueryString(q);
    const headers = await this.appendHeaders(!dataIsFile ? this.jsonHeader : {});
    let body = data ? JSON.stringify(data) : (null as string | null | FormData);
    if (dataIsFile) {
      const file = data as File | undefined;
      const formData = new FormData();
      if (file) {
        formData.append('file', file, file.name);
        body = formData;
      }
    }

    var resultPromise = () =>
      fetch(this.serviceBase + url + (queryString ? '?' + queryString : ''), {
        method: method,
        body: body,
        headers: headers,
        signal: this.abortController ? this.abortController.signal : undefined,
      });
    return this.executeRequest(resultPromise);
  }
  protected async requestExport(
    method: string,
    url: string,
    q: any = {},
    data?: any,
    dataIsFile: boolean = false,
  ): Promise<Response> {
    let queryString = this.buildQueryString(q);
    const headers = await this.appendHeaders(!dataIsFile ? this.exportHeader : {});
    let body = data ? JSON.stringify(data) : (null as string | null | FormData);
    if (dataIsFile) {
      const file = data as File | undefined;
      const formData = new FormData();
      if (file) {
        formData.append('file', file, file.name);
        body = formData;
      }
    }

    var resultPromise = () =>
      fetch(this.serviceBase + url + (queryString ? '?' + queryString : ''), {
        method: method,
        body: body,
        headers: headers,
        signal: this.abortController ? this.abortController.signal : undefined,
      });
    return this.executeRequest(resultPromise);
  }

  protected async executeRequest(response: () => Promise<Response>): Promise<Response> {
    var result = await response();
    this.checkStatus(result);
    return result;
  }

  protected checkStatus(response: Response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      if (response.status === 403) {
        window.location.href = '/forbidden';
      }
      let errorMessage: string = response.statusText;
      let error = new Error(errorMessage) as any;
      error.response = response;
      throw error;
    }
  }

  protected extractHeaderFilename(response: Response): string | undefined {
    let contentHeaderSegments = (response.headers.get('content-disposition') || '').split(';');

    const findMatch = (segments: string[], pattern: RegExp) =>
      segments
        .map((segment) => pattern.exec(segment))
        .filter((matches) => matches && matches.length > 1)
        .map((matches) => matches![1] as string)
        .find(() => true);

    return (
      findMatch(contentHeaderSegments, /filename\*="?UTF-8''([^;"]*)"?;?/) ||
      findMatch(contentHeaderSegments, /filename="?([^;"]*)"?;?/)
    );
  } 

  protected appendHeaders(jsonHeader: any = {}): any {
    const headers = { ...jsonHeader };
    headers['pragma'] = 'no-cache';
    headers['cache-control'] = 'no-cache';

    const appVersion = getAppVersion();
    if (appVersion) {
      headers['client-application-version'] = appVersion;
    }

    return headers;
  }

  buildQueryString(data: any): string {
    return Object.keys(data)
      .filter((x) => data[x] !== undefined)
      .map((key: string) => {
        if (data[key] instanceof Array) {
          let param = '';
          let array: any[] = data[key] as any[];
          for (let i = 0; i < array.length; i++) {
            param += key + '=' + encodeURI(array[i]);
            if (i < array.length - 1) {
              param += '&';
            }
          }
          return param;
        }
        return key + '=' + data[key];
      })
      .join('&');
  }
}
